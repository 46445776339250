import React, {useCallback, useMemo} from "react";
import {useState} from "react";
import {Card, TextArea, Select, IconButton, Link, Text, Snack, Snackbar, Icon} from "@nike/eds";

import {RawEventForm} from "../model/RawEventForm";
import {SimulationService} from "../service/SimulationService";

import * as vkbeautify from "vkbeautify";
import './RawEvent.css'
import {useDropzone} from 'react-dropzone'
import { SupportAPIResponse} from "../graphql/api";

const baseStyle = {
    padding: '5px',
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100px',
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function RawEvent(props): JSX.Element {

    const simulationService: SimulationService = props.simulationService
    const environment = process.env.REACT_APP_ENV

    const operationOptions = [
        {value: "WEP", label: "Warehouse Entry Pack"},
        {value: "WEPCR", label: "WEP Customs Released"},
        {value: "WEPNCTN", label: "No Customs Treatment Needed"},
        {value: "NODEINVENTORY", label: "Node Inventory"},
        {value: "NODEINVENTORYLOCK", label: "Node Inventory Lock"},
        {value: "NGHIR", label: "Node Goods Holder Item Received"},
        {value: "NGR", label: "Node Goods Received"},
        {value: "SHIPMENT", label: "Shipment"},
        {value: "DEDUCTION", label: "Deduction"},
        {value: "DUMMY_DEDUCTION", label: "Dummy Deduction"},
        {value: "DUMMY_RETURN_RECEIPT", label: "Dummy Return Receipt"}
    ];

    const [operation, setOperation] = useState('');
    const [rawEvent, setRawEvent] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        valid: true,
        operationErrors: false,
        operation: '',
        rawEventErrors: false,
        rawEvent: ''
    });
    const [returnMessage, setReturnMessage] = useState('');
    const [showSnackSuccess, setShowSnackSuccess] = useState(false);
    const [showSnackError, setShowSnackError] = useState(false);

    const operationChangeHandler = (event) => {
        setOperation(event.value)
    }
    const rawEventChangeHandler = (event) => {
        setRawEvent(event.target.value)
    }

    const validateForm = () => {
        let response = {valid: true, operationErrors: false, operation: '', rawEventErrors: false, rawEvent: ''};
        if (operation === '') {
            response.valid = false;
            response.operationErrors = true;
            response.operation = "No operation selected";
        }
        if (rawEvent === '') {
            response.valid = false;
            response.rawEventErrors = true;
            response.rawEvent = "No event data entered";
        }
        setValidationErrors(response);
        return response.valid;
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (validateForm()) {
            let testPrintData: RawEventForm = getEventForm();
            setReturnMessage("")
            setShowSnackError(false);
            setShowSnackSuccess(false);
            simulationService.startSimulate(testPrintData)
                .then(r => {
                    if (r.data) {
                        const dataResponse = r[Object.getOwnPropertyNames(r)[0]];
                        const apiResponse = dataResponse[Object.getOwnPropertyNames(dataResponse)[0]] as SupportAPIResponse;
                        let text = apiResponse.message;
                        setReturnMessage((text as string));
                        if (apiResponse.success === false) {
                            setShowSnackError(true)
                        } else {
                            setShowSnackSuccess(true)
                        }
                    }
                    if (r.errors) {
                        let text = r.errors[0].message;
                        setReturnMessage(text);
                        setShowSnackError(true)
                    }
                })
                .catch(error => {
                    setReturnMessage(error.message);
                    setShowSnackError(true)
                })
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (e.target !== null) {
                    var contents = e.target.result !== null ? e.target.result.toString() : '';
                    try {
                        let formattedJson = vkbeautify.json(contents, 2);
                        setRawEvent(formattedJson);
                    } catch {
                        setRawEvent(contents);
                    }
                }
            };
            reader.readAsText(acceptedFiles[0]);
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        maxFiles:1,
        accept: {
            'application/json': ['.json']
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragReject
    ]);

    function getEventForm(): RawEventForm {
        let testPrintData: RawEventForm = {
            operation: operation,
            rawEvent: rawEvent
        }
        return testPrintData;
    }

    return (
        <div>
            <form onSubmit={submitHandler}>
                <Card className='eds-flex eds-flex--justify-content-center'>
                    { environment === 'PR' &&
                        <span className="eds-color--white eds-background--danger  eds-spacing--p-12 eds--radii-4">!! This is a PRODUCTION environment! Sending in data will lead to changes in Customs Inventory !!</span>
                    }
                </Card>

                <div className="eds-grid eds-grid--m-cols-4">
                    <div className="eds-spacing--ml-12">
                            <Select onChange={operationChangeHandler}
                                    id="operationSelect"
                                    options={operationOptions}
                                    label={validationErrors.operationErrors ? "Source" : "Source *"}
                                    hasErrors={validationErrors.operationErrors}
                                    errorMessage="You haven't made a choice"
                            />
                    </div>
                    <div className="eds-spacing--ml-12 eds-spacing--mt-24">
                        <IconButton
                            icon="Play"
                            type={"submit"}
                            size={"small"}
                            style={{marginRight: "5px"}}
                            label={""}/>
                        <IconButton
                            onClick={() => window.location.reload()}
                            icon="Undo"
                            size={"small"}
                            style={{marginRight: "5px"}}
                            label={""}/>
                    </div>
                </div>

                <div className="eds-grid eds-grid--m-cols-4">
                        <div className="container eds-spacing--ml-12 eds-spacing--mt-12">
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()} />
                                {isDragAccept && (<p>Drop the file</p>)}
                                {isDragReject && (<p>File is not a JSON</p>)}
                                {!isDragActive && (<><p>Drag and drop JSON</p>
                                    <label style={{cursor: 'pointer'}}
                                           className="eds-spacing--mt-12 eds-button eds-button--secondary eds-button--small">
                                    <Icon
                                        name="Upload"/>
                                    Browse files</label></>)}
                            </div>
                        </div>
                </div>

                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                    <Snackbar>
                        {showSnackSuccess && (
                            <Snack id="success" status={"success"} onDismiss={id => setShowSnackSuccess(false)}>
                                <Text as={"p"} font={"body-2"}>Simulation succesfully processed.</Text>
                                <Link id="printRequest" href={"/request-overview"}>Go to request overview.</Link>
                            </Snack>
                        )}
                        {showSnackError && (
                            <Snack id="error" status={"error"} onDismiss={id => setShowSnackError(false)}>
                                <Text as={"p"} font={"body-2"}>Simulation failed with error:</Text>
                                <Text as={"p"} font={"body-2"} style={{color: 'red'}}>{returnMessage}</Text>
                            </Snack>
                        )}
                    </Snackbar>
                </Card>

                <Card className="eds-grid--m-row-1 eds-spacing--p-8">
                    <TextArea onChange={rawEventChangeHandler}
                              value={rawEvent}
                              id="rawEvent"
                              label=""
                              placeholder="Content raw event"
                              minRows={25}
                              autoResize={true}
                    />
                </Card>

            </form>
        </div>
    );
}
