import {oktaAuth} from "../components/config/OktaConfig";
import {RawEventForm} from "../model/RawEventForm";
import AWSAppSyncClient, {AUTH_TYPE} from "aws-appsync";
import gql from "graphql-tag";
import {
    submitCustomCustomsReleasedEvent,
    submitCustomNoCustomsTreatmentNeededEvent,
    submitCustomWepEvent,
    submitNodeInventoryEvent,
    submitNodeGoodsHolderItemReceivedEvent,
    submitNodeGoodsReceivedEvent,
    submitNodeInventoryLockEvent,
    submitShipmentEvent,
    submitDeductionEvent,
    submitDummyDeductionEvent,
    submitDummyReturnReceiptEvent
} from "../graphql/mutations";

export class SimulationService {

    startSimulate(eventData: RawEventForm) {
        const apiAddress: string | undefined = process.env["REACT_APP_SERVICE_ENDPOINT_" + eventData.operation];
        if (!apiAddress) {
            throw new Error("No API address configured for " + eventData.operation);
        }
        console.log("Contacting GraphQL on " + apiAddress);
        const appsync : AWSAppSyncClient<any> = new AWSAppSyncClient({
            url: apiAddress,
            region: 'eu-west-1',
            auth: {
                type: AUTH_TYPE.OPENID_CONNECT,
                jwtToken: async () => oktaAuth.getAccessToken()!,
            },
            disableOffline: true
        });
        
        return appsync.mutate({
            mutation: gql(this.getGraphQlMutation(eventData.operation)),
            fetchPolicy: 'no-cache',
            variables: {
                rawEvent: eventData.rawEvent
            }
        });
    }

    getGraphQlMutation(operation: string | undefined): any {
        if (operation === "WEP") {
            return submitCustomWepEvent;
        }
        if (operation === "WEPNCTN") {
            return submitCustomNoCustomsTreatmentNeededEvent;
        }
        if (operation === "WEPCR") {
            return submitCustomCustomsReleasedEvent;
        }
        if (operation === "NODEINVENTORY") {
            return submitNodeInventoryEvent;
        }
        if (operation === "NODEINVENTORYLOCK") {
            return submitNodeInventoryLockEvent;
        }
        if (operation === "NGHIR") {
            return submitNodeGoodsHolderItemReceivedEvent;
        }
        if (operation === "NGR") {
            return submitNodeGoodsReceivedEvent;
        }
        if (operation === "SHIPMENT") {
            return submitShipmentEvent;
        }
        if (operation === "DEDUCTION") {
            return submitDeductionEvent;
        }
        if (operation === "DUMMY_DEDUCTION") {
            return submitDummyDeductionEvent;
        }
        if (operation === "DUMMY_RETURN_RECEIPT") {
            return submitDummyReturnReceiptEvent;
        }
        throw new Error("Unsupported operation");
    }

    getPayload(testPrintForm: RawEventForm): Promise<Response> {
        const apiAddress: string | undefined = process.env.REACT_APP_SERVICE_ENDPOINT;
        const token = oktaAuth.getAccessToken();

        if (!apiAddress) throw new Error('API address not defined');
        if (!token) throw new Error('No token found for authorization');
        if (!testPrintForm.operation) throw new Error('No source found');

        return fetch(apiAddress + "/v1/payload/" + testPrintForm.operation, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

}