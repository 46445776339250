/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const submitCustomWepEvent = /* GraphQL */ `mutation SubmitCustomWepEvent($rawEvent: String!) {
  submitCustomWepEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitCustomWepEventMutationVariables,
  APITypes.SubmitCustomWepEventMutation
>;
export const submitCustomNoCustomsTreatmentNeededEvent = /* GraphQL */ `mutation SubmitCustomNoCustomsTreatmentNeededEvent($rawEvent: String!) {
  submitCustomNoCustomsTreatmentNeededEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitCustomNoCustomsTreatmentNeededEventMutationVariables,
  APITypes.SubmitCustomNoCustomsTreatmentNeededEventMutation
>;
export const submitCustomCustomsReleasedEvent = /* GraphQL */ `mutation SubmitCustomCustomsReleasedEvent($rawEvent: String!) {
  submitCustomCustomsReleasedEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitCustomCustomsReleasedEventMutationVariables,
  APITypes.SubmitCustomCustomsReleasedEventMutation
>;
export const submitNodeInventoryEvent = /* GraphQL */ `mutation SubmitNodeInventoryEvent($rawEvent: String!) {
  submitNodeInventoryEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitNodeInventoryEventMutationVariables,
  APITypes.SubmitNodeInventoryEventMutation
>;
export const submitNodeInventoryLockEvent = /* GraphQL */ `mutation SubmitNodeInventoryLockEvent($rawEvent: String!) {
  submitNodeInventoryLockEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitNodeInventoryLockEventMutationVariables,
  APITypes.SubmitNodeInventoryLockEventMutation
>;
export const submitNodeGoodsHolderItemReceivedEvent = /* GraphQL */ `mutation SubmitNodeGoodsHolderItemReceivedEvent($rawEvent: String!) {
  submitNodeGoodsHolderItemReceivedEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitNodeGoodsHolderItemReceivedEventMutationVariables,
  APITypes.SubmitNodeGoodsHolderItemReceivedEventMutation
>;
export const submitNodeGoodsReceivedEvent = /* GraphQL */ `mutation SubmitNodeGoodsReceivedEvent($rawEvent: String!) {
  submitNodeGoodsReceivedEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitNodeGoodsReceivedEventMutationVariables,
  APITypes.SubmitNodeGoodsReceivedEventMutation
>;
export const submitShipmentEvent = /* GraphQL */ `mutation SubmitShipmentEvent($rawEvent: String!) {
  submitShipmentEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitShipmentEventMutationVariables,
  APITypes.SubmitShipmentEventMutation
>;
export const submitWarehouseRemovalEvent = /* GraphQL */ `mutation SubmitWarehouseRemovalEvent($rawEvent: String!) {
  submitWarehouseRemovalEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitWarehouseRemovalEventMutationVariables,
  APITypes.SubmitWarehouseRemovalEventMutation
>;
export const submitDummyReturnReceiptEvent = /* GraphQL */ `mutation SubmitDummyReturnReceiptEvent($rawEvent: String!) {
  submitDummyReturnReceiptEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitDummyReturnReceiptEventMutationVariables,
  APITypes.SubmitDummyReturnReceiptEventMutation
>;
export const submitTantEvent = /* GraphQL */ `mutation SubmitTantEvent($rawEvent: String!) {
  submitTantEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitTantEventMutationVariables,
  APITypes.SubmitTantEventMutation
>;
export const submitStockMgmtEvent = /* GraphQL */ `mutation SubmitStockMgmtEvent($rawEvent: String!) {
  submitStockMgmtEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitStockMgmtEventMutationVariables,
  APITypes.SubmitStockMgmtEventMutation
>;
export const submitDrsRemovalEvent = /* GraphQL */ `mutation SubmitDrsRemovalEvent($rawEvent: String!) {
  submitDrsRemovalEvent(rawEvent: $rawEvent) {
    message
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitDrsRemovalEventMutationVariables,
  APITypes.SubmitDrsRemovalEventMutation
>;
